
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import NewFeatureReferenceModel from '../../models/Keystone_v2/NewFeatureReferenceModel'
import NewFeatureRefTableStatusIcon from './Form/NewFeatureRefTableStatusIcon.vue'
import NewFeatureRefsMembers from '@/components/KeystoneV2/NewFeatureRefsMembers.vue'

export const dspStatusList = [
  'appnexusStatus',
  'dbmStatus',
  'youtubeStatus',
  'mediamathStatus',
  'thetradedeskStatus',
  'beeswaxStatus',
  'kayzenStatus'
]

@Component({
  components: {
    NewFeatureRefsMembers,
    NewFeatureRefTableStatusIcon
  }
})
export default class NewFeatureRefsManagerTable extends Vue {
  @Prop({ required: true }) newFeatureReferences: Array<NewFeatureReferenceModel>
  @Prop({ required: false, default: false }) loading: boolean

  // TABLE
  nbPerPage: number = 25 // default

  readonly tableHeaders: Array<any> = [
    { text: 'ID', value: 'id', sortable: true },
    { text: 'Dev name', value: 'devName', sortable: true },
    { text: 'Feature', value: 'featureName', sortable: false },
    { text: 'Level', value: 'applicationLevel', sortable: false },
    { text: '', value: 'appnexusStatus', sortable: true, dsp: 'appnexus' },
    { text: '', value: 'dbmStatus', sortable: true, dsp: 'dbm' },
    { text: '', value: 'youtubeStatus', sortable: true, dsp: 'youtube' },
    { text: '', value: 'mediamathStatus', sortable: true, dsp: 'mediamath' },
    { text: '', value: 'thetradedeskStatus', sortable: true, dsp: 'thetradedesk' },
    { text: '', value: 'beeswaxStatus', sortable: true, dsp: 'beeswax' },
    { text: '', value: 'kayzenStatus', sortable: true, dsp: 'kayzen' },
    { text: '', value: 'metaStatus', sortable: true, dsp: 'meta' },
    { text: '', value: 'action', sortable: false }
  ]

  readonly dspStatusList = [
    'appnexusStatus',
    'dbmStatus',
    'youtubeStatus',
    'mediamathStatus',
    'thetradedeskStatus',
    'beeswaxStatus',
    'kayzenStatus',
    'metaStatus'
  ]

  // DIALOG
  dialog: boolean = false
  feature: NewFeatureReferenceModel = null
  featureToEdit: NewFeatureReferenceModel = null

  // DIALOG
  openEditDialog (featureId: number) {
    this.featureToEdit = this.newFeatureReferences.filter((x: NewFeatureReferenceModel) => x.id === featureId)[0]
    this.$emit('updateNewFeatureRef', this.featureToEdit)
  }

  openMembersByFeatureIdDialog (feature: NewFeatureReferenceModel) {
    this.feature = feature
    this.dialog = true
  }

  get featureIdSelected () :number | null {
    return this.feature ? this.feature.id : null
  }

  // UTILS
  getPrettyDspName (dsp: string) {
    return this.$commonUtils.dspPrettyPrint(dsp, false)
  }
}
