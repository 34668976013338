import { NewFeatureReferenceApiArgs } from '../../../types/new_features_types'
import { InstructionDsp, NotBetaInstructionDsp } from '../../../types/instruction_type'

export default class NewFeatureReferenceModel {
  id: number = null
  devName: string = null
  featureName: string = null
  description: string = null
  applicationLevel: string = null
  appnexusStatus: string = null
  dbmStatus: string = null
  youtubeStatus: string = null
  mediamathStatus: string = null
  thetradedeskStatus: string = null
  beeswaxStatus: string = null
  kayzenStatus: string = null
  googleadsStatus: string = null
  metaStatus: string = null
  newFeatureActive: boolean = null
  legacyFeatureActive: boolean = null
  updated: boolean = false

  constructor (apiObject: NewFeatureReferenceApiArgs = null) {
    if (apiObject != null) {
      this.id = 'id' in apiObject && apiObject.id != null ? apiObject.id : null
      this.devName = 'dev_name' in apiObject && apiObject.dev_name != null ? apiObject.dev_name : null
      this.featureName = 'feature_name' in apiObject && apiObject.feature_name != null ? apiObject.feature_name : null
      this.description = 'description' in apiObject && apiObject.description != null ? apiObject.description : null
      this.applicationLevel = 'application_level' in apiObject && apiObject.application_level != null ? apiObject.application_level : null
      this.appnexusStatus = 'appnexus_status' in apiObject && apiObject.appnexus_status != null ? apiObject.appnexus_status : null
      this.dbmStatus = 'dbm_status' in apiObject && apiObject.dbm_status != null ? apiObject.dbm_status : null
      this.youtubeStatus = 'youtube_status' in apiObject && apiObject.youtube_status != null ? apiObject.youtube_status : null
      this.mediamathStatus = 'mediamath_status' in apiObject && apiObject.mediamath_status != null ? apiObject.mediamath_status : null
      this.thetradedeskStatus = 'thetradedesk_status' in apiObject && apiObject.thetradedesk_status != null ? apiObject.thetradedesk_status : null
      this.beeswaxStatus = 'beeswax_status' in apiObject && apiObject.beeswax_status != null ? apiObject.beeswax_status : null
      this.kayzenStatus = 'kayzen_status' in apiObject && apiObject.kayzen_status != null ? apiObject.kayzen_status : null
      this.metaStatus = 'meta_status' in apiObject && apiObject.meta_status != null ? apiObject.meta_status : null
      this.newFeatureActive = 'new_feature_active' in apiObject && apiObject.new_feature_active != null ? apiObject.new_feature_active : null
      this.legacyFeatureActive = 'legacy_feature_active' in apiObject && apiObject.legacy_feature_active != null ? apiObject.legacy_feature_active : null
    } else {
      this.setDefaultValues()
    }
  }

  getAsApiArgs (linkFeature: boolean = false): NewFeatureReferenceApiArgs {
    const baseToReturn: NewFeatureReferenceApiArgs = {
      id: this.id,
      dev_name: this.devName,
      feature_name: this.featureName,
      description: this.description
    }

    let insertOrUpdateNFR = {}
    let createOrDeleteJoinedTable = {}

    if (linkFeature === true) {
      createOrDeleteJoinedTable = {
        new_feature_active: this.newFeatureActive,
        legacy_feature_active: this.legacyFeatureActive
      }
    } else {
      insertOrUpdateNFR = {
        appnexus_status: this.appnexusStatus,
        dbm_status: this.dbmStatus,
        youtube_status: this.youtubeStatus,
        mediamath_status: this.mediamathStatus,
        thetradedesk_status: this.thetradedeskStatus,
        beeswax_status: this.beeswaxStatus,
        kayzen_status: this.kayzenStatus,
        meta_status: this.metaStatus
      }
    }

    const toReturn = { ...baseToReturn, ...insertOrUpdateNFR, ...createOrDeleteJoinedTable }

    let cleanedToReturn: NewFeatureReferenceApiArgs = {}
    // rm empty value
    for (let key in toReturn) {
      if (toReturn[key as keyof NewFeatureReferenceApiArgs] !== null &&
        toReturn[key as keyof NewFeatureReferenceApiArgs] !== undefined &&
        toReturn[key as keyof NewFeatureReferenceApiArgs] !== '') {
        // @ts-ignore
        cleanedToReturn[key as keyof NewFeatureReferenceApiArgs] = toReturn[key as keyof NewFeatureReferenceApiArgs]
      }
    }

    return cleanedToReturn
  }

  setDefaultValues () {
    this.id = null
    this.devName = null // string
    this.featureName = null // string
    this.description = null // string
    this.applicationLevel = 'member' // string
    this.appnexusStatus = 'incompatible' // string
    this.dbmStatus = 'incompatible' // string
    this.youtubeStatus = 'incompatible' // string
    this.mediamathStatus = 'incompatible' // string
    this.thetradedeskStatus = 'incompatible' // string
    this.beeswaxStatus = 'incompatible' // string
    this.kayzenStatus = 'incompatible' // string
    this.metaStatus = 'incompatible' // string
    this.newFeatureActive = false
    this.legacyFeatureActive = false
    this.updated = false
  }

  isFeatureOn (dsp: InstructionDsp): boolean {
    // for beta dsp (like meta) we consider the feature is off
    if (!this[`${dsp}Status` as keyof NewFeatureReferenceModel]) {
      return false
    }
    switch (this[`${dsp}Status` as keyof NewFeatureReferenceModel]) {
      case 'incompatible':
        return false
      case 'alpha':
      case 'beta':
        return this.newFeatureActive
      case 'released_with_exception':
        return !this.legacyFeatureActive
      case 'fully_released':
        return true
      default:
        return false
    }
  }
}
